@import "../../styles/globals";

.searchresult-panel {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding-bottom: 80px;
    

    .flex-button {
        display: flex;
        
        div {
            line-height: 22px;
        }
    }
    
    .arrow-left-icon {
        height: 20px;
        width: 20px;
        margin-left: -16px;
        margin-right: 4px;
    }
    
    .loading-panel {
        padding: 4px 16px;
    }

    .header {
        width: 100%;
        padding: 4px 16px 4px 16px;
        max-height: 50vh;
        overflow-y: auto;
        

        .title {
            display: grid;
            grid-template-columns: auto 44px;
            
            h3 {
                margin: auto 0;
            }
        }
    }

    .special-search-header {
        width: 100%;
        padding: 12px 0;
       
        .individ-description {
            font-size: 20px;
            padding: 12px 0px 0px 0px;
        }
    }

    .result-list {
        overflow: auto;
        // Setter min-høyden til høyden på et element i lista
        min-height: 70px;
    }
    
    .footer-buttons {
        display: flex;
        justify-content: space-around;
        margin: 12px;
    }

    .navigate {
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 8px 0px;
        align-items: center;

        .page-count {
            padding: 0 12px;
        }
    }
}


@media (min-width: $bp_medium) {
    .searchresult-panel {
        // Denne paddinga må matche høyden på menybar-headeren
        padding-bottom: 108px;
    }
}
