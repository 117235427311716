.watermark {
  margin: 4px;
  border: 2px solid #CB4335;
  border-radius: 5px;
  color: #CB4335;
  font-size: 16px;
  padding: 3px 4px 2px;
  width: fit-content;
  height: fit-content;
  pointer-events: none;
  background-color: #FFFFFF;
}

.watermark-map-layer {
  font-size: 30vw;
  height: 100vh;
  width: 100vw;
  text-align: center;
  color: #CB4335;
  pointer-events: none;
  opacity: 0.3;
  position: absolute;
  z-index: 8;
  background-repeat: repeat;
  letter-spacing: 15px;

  div {
    margin: auto;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%) rotate(-15deg);
  }
}

@media (min-width: 600px) {
  .watermark-map-layer {
    font-size: 20vw;
    margin-left: 10%;
  }
}

