﻿@use 'colors' as *;

html {
  --primaryColor: #{$mdPrimaryColor};
  --primaryColor120: #{$mdPrimaryColor120};
  --primaryColor80: #{$mdPrimaryColor80};
  --primaryColor20: #{$mdPrimaryColor20};
  --primaryColor10: #{$mdPrimaryColor10};

  --lightPrimaryColor: #{$mdLightPrimaryColor};
  --darkPrimaryColor: #{$mdDarkPrimaryColor};

  --secondaryColor: #{$mdSecondaryColor};
  --secondaryColor80: #{$mdSecondaryColor80};
  --secondaryColor60: #{$mdSecondaryColor60};
  --secondaryColor40: #{$mdSecondaryColor40};
  --secondaryColor20: #{$mdSecondaryColor20};

  --textColorDark: #{$mdTextColor};
  --textColorLight: #fff;

  --warningBackgroundColor: #{$mdWarningBackgroundColor};
  --warningColor: #{$mdWarningColor};

  --errorColor: #{$mdErrorColor};
  --errorColor120: #{$mdErrorColor120};

  --greyColor: #{$mdGreyColor};
  --greyColor80: #{$mdGreyColor80};
  --greyColor60: #{$mdGreyColor60};
  --greyColor40: #{$mdGreyColor40};
  --greyColor20: #{$mdGreyColor20};
  --greyColor10: #{$mdGreyColor10};

  --greenColor60: #{$mdGreenColor60};
  
  --backgroundColor: #fff;
}

html[data-color-theme="nv"] {
  --primaryColor: #{$nvPrimaryColor};
  --primaryColor120: #{$nvPrimaryColor120};
  --primaryColor80: #{$nvPrimaryColor80};
  --primaryColor20: #{$nvPrimaryColor20};
  --primaryColor10: #{$nvPrimaryColor10};

  --lightPrimaryColor: #{$nvLightPrimaryColor};
  --darkPrimaryColor: #{$nvDarkPrimaryColor};

  --secondaryColor: #{$nvSecondaryColor};
  --secondaryColor80: #{$nvSecondaryColor80};
  --secondaryColor60: #{$nvSecondaryColor60};
  --secondaryColor40: #{$nvSecondaryColor40};
  --secondaryColor20: #{$nvSecondaryColor20};
}