@use '../../styles/theme' as *;
@use '../../styles/globals.scss';
@use '../../styles/spacing.scss' as s;

.damage-cause {
  margin: s.$spacing--xl auto;
  display: flex;
  flex-direction: column;
}

.damage-cause-table-and-chart {
    margin: 0 -6%;
}

.damage-cause-table {
  margin: s.$spacing--xs 0;
  flex: 1 2;
  max-width: 820px;
  
  & th {
    padding: s.$spacing--xs;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
  }

  & th:first-of-type {
    padding-left: s.$spacing--s;
    text-align: left;
  }

  & td {
    border: 0;
    padding: s.$spacing--xs;
    font-size: 16px;
    text-align: right;
  }

  & td:first-of-type {
    padding-left: s.$spacing--s;
    text-align: left;
  }

  & > tbody > tr:nth-of-type(2n + 1) {
    background-color: var(--greyColor10);
  }
  
  thead {
    border: 1px solid var(--greyColor20);
  }
}

.damage-cause-pie-chart {
  margin: auto auto;
  padding: 16px;
}

.damage-cause-table-small {
  border: 1px solid var(--greyColor60);
  
  td {
    border-bottom: 1px solid var(--greyColor60);;
    padding: s.$spacing--xs s.$spacing--s s.$spacing--xs s.$spacing--xs;
  }
}

@media (min-width: globals.$bp_xtra_small) {
    .damage-cause-table-and-chart {
        margin: unset;
    }
}

@media (min-width: globals.$bp_medium) {
    .damage-cause-table-and-chart {
        display: flex;
        flex-wrap: wrap;
    }
}
