@import '../../styles/globals.scss';
#map {
    .toolbar {
        position: absolute;
        top: 20px;
        right: 10px;
        width: 40px;
        min-width: 40px;
        max-width: 130px;
        display: flex;
        z-index: 9;
        flex-direction: column;

        button {
            border: none;
            padding: 0;
            margin: 0;
        }
        .tool {
            height: 40px;
            box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);

            &:hover {
                width: 130px;
            }

            img {
                padding: 5px;
                width: 40px;
            }
        }

        .maptool-icon-wrapper {
            width: 40px;
            height: 40px;
            padding: 4px;
            display: inline-block;
        }

        &:hover {
            width: 140px;

            .tool {
                display: flex;
                justify-content: space-between;
            }

            .maptool-text {
                height: 40px;
                line-height: 40px;
                padding-left: 12px;
                visibility: visible;
                text-align: left;
            }
        }

        &.shifted {
            right: 310px;
        }

        .maptool-text {
            overflow: hidden;
            height: 0px;
            visibility: hidden;
            float: left;
            width: 90px;

        }
    }
}

@media (min-width: $bp_small) {
    #map {
        .toolbar {
            top: 15%;
        }
    }
}
