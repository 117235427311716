@use '../../styles/theme' as *;
@use '../../styles/spacing';
@use '../../styles/globals';

.expansion-panel {
  margin-bottom: spacing.$spacing--xs;
  color: var(--textColorDark);
  box-shadow: none;
  
  &.dark {
    background-color: var(--primaryColor10);
  }
  
  &.light {
    background-color: var(--backgroundColor);
  }
  
  .panel-content {
    padding: spacing.$spacing--m;
  }
  
  .panel-open {
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 300ms ease-in;
  }

  .panel-closed {
      overflow: hidden;
      visibility: hidden;
      max-height: 0;
      transition: max-height 300ms, visibility 400ms;
      input {
          visibility: hidden;
          transition: visibility 400ms;
      }
  }

  .overflow {
      overflow: visible;
  }

  @media (min-width: globals.$bp_xtra_small) {
      .panel-open {
          max-height: 600px;

      }
  }
}

.expansion-panel-title {
  font-size: 16px;
  padding: spacing.$spacing--s spacing.$spacing--m;
  cursor: pointer;
  user-select: none;
  border: none;
  width: 100%;
  text-align: left;
  display: flex;
  gap: spacing.$spacing--s;
  align-items: center;
  color: var(--textColorDark);

  &:focus {
    outline: 1px solid var(--darkPrimaryColor);
    outline-offset: 2px;
  }
  
  &:hover {
    text-decoration: underline;
  }
  
  &.dark {
    background-color: var(--primaryColor10);

    &:hover {
      background-color: var(--primaryColor20);
    }
  }
  
  &.light {
    background-color: var(--backgroundColor);

    &:hover {
      background-color: var(--primaryColor10);
    }
  }
  
  span:first-of-type {
    width: 100%;
  }

  .icon {
    height: 24px;
    width: 24px;
    margin: auto 0;
    
    svg {
      height: 24px;
      width: 24px;
      transform: rotate(0deg);
      transition: transform 300ms ease;
    }
  }

  .expanded {
    svg {
        transform: rotate(180deg);
        transition: transform 300ms ease;
    }
  }
}