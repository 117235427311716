#map {
    display: block;
    height: 100%;
    touch-action: none;
}

#popup-content {
    height: 50px;
    width: 250px;
    position: absolute;
    bottom: 0;
    right: 0;
}