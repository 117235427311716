.error-wrapper {
    margin: auto;
    padding: 10px;
    width: 90%;
    z-index: 1000;
    min-width: 200px;
    max-width: 600px;
    min-height: 300px;
    max-height: 100vh;
    background-color: white;

    details {
        margin: 20px auto;
    }
    button {
        margin: 10px auto;
    }
}
