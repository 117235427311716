.layer-selector-container {
  width: 100%
}

.layer-selector-header {
  min-height: 70px;
  display: flex;
  padding: 0 21px;

  h2 {
    flex: 1 2;
    margin: auto 0;
  }

  button {
    margin: auto;
  }
}

.layer-selector-body {
  padding: 21px;
}

.layer-group-wrapper {
  margin-bottom: 20px;
    h3 {
      padding-bottom: 12px;
    }
}

.image-switch-container {
  max-width: 280px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.image-button {
  width: 70px;
  padding: 0;
  border: 0;
  background-color: unset;

  img {
    height: 60px;
    width: 60px;
  }

  &:focus {
    outline: none;
    
  }

}

.layer-selector-image-switch{
  display: flex;
  justify-content: space-between;
  width: 220px;
  img {
    padding: 0 10px;
  }
}

@media (min-width: 600px) {
  .layer-selector-container {
    width: 400px
  }

  .layer-group-wrapper {
    margin-bottom: 40px;
  
  }

}
