@use '../../styles/theme' as *;
@import '../../styles/globals.scss';

.compensation-selector-content {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 48px;
  justify-content: flex-start;
  background-color: var(--primaryColor20);

  margin: 0 -6% 50px -6%;
    padding: 24px 20px;
}

.location-search {
  width: 100%;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  label {
    margin: 6px 0;
    font-weight: bold;
  }
}

.compensation-help-autosearch{
  /* For at elementet skal bruke all plass i container.
     Ellers vil den flytte seg til hoyre for inputfeltene pa
     store skjermer */
  width: 100%;
  margin: 0;
}

@media (min-width: $bp_xtra_small) {
  .compensation-selector-content {
    margin: 0 0 50px 0;
  }
}

@media (min-width: $bp_medium) {
  .compensation-selector-content {
    padding: 24px 36px;
  }
}
