$padding-sides: 28px;

.menu-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 390px;
}

.menu-content {
    flex: 1 0 auto;
}

.menu-title-container {
    display: flex;
    padding: 6px $padding-sides;
    border-bottom: 1px solid;

    .menu-title {
        flex: 1 2;
    }

    .close-menu {
        margin: auto;
    }
}

.language-container {
    padding: 6px $padding-sides;
}

.navigation {
    padding: 16px $padding-sides;
    font-size: 18px;


    ul {
        line-height: 35px;

        li {
            list-style-type: none;
        }
    }

    a, button {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
            outline: none;
        }
    }

    .selected {
        color: inherit;
        font-weight: bold;
    }

    .rovdyrfakta {
        padding-left: 16px;
    }

    .expand-more {
        vertical-align: middle;

        &.openRovdyr {
            transform: rotate(180deg);
        }
    }

    .open-in-new {
        vertical-align: middle;
        margin-left: 8px;
    }
}


.menu-footer {
    flex-shrink: 0;

    .contactinfo-container {
        margin: 28px 20px;
        display: flex;

        .contactinfo-text {
            margin-left: 10px;
            line-height: 1.2;

            a {
                color: inherit !important;
                font-weight: lighter;
            }
        }
    }
}