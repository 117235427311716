@use '../../styles/theme' as *;
@use '../../styles/spacing';

.expansion-field {
  display: flex;
  flex-direction: column;
  box-shadow: none;

  .panel-closed {
    overflow: hidden;
    visibility: hidden;
    max-height: 0;
    transition: max-height 300ms, visibility 400ms;
    
    input {
      visibility: hidden;
      transition: visibility 400ms;
    }
  }

  .panel-open {
    overflow: hidden;
    max-height: 500px;
    transition: max-height 300ms ease-in;
  }

  .main-content {
    padding: spacing.$spacing--s 0;
  }

  .overflow {
    overflow: visible;
  }
}

.expansion-field-title {
  font-size: 16px;
  padding: spacing.$spacing--s;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;    
  background-color: var(--backgroundColor);
  color: var(--primaryColor);

  span {
    margin: auto 0;
  }
  
  &:focus {
    outline: 1px solid var(--primaryColor80);
    color: var(--textColorDark);
    font-weight: bold
  }

  .panel-icon {
    margin-right: spacing.$spacing--xs;
    height: 19px;
    width: 19px;
    
    svg {
      height: 19px;
      width: 19px;
      transform: rotate(0deg);
      transition: transform 300ms ease;
    }
  }
  
  .expanded {
    svg {
      transform: rotate(180deg);
      transition: transform 300ms ease;
    }
  }
}