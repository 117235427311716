/* The switch - the box around the slider */
.toggle-switch {
    display: flex;
    align-items: center;

  &:focus-within {
    font-weight: bold;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0
  }
}

.switch-label {
    position: relative;
    display: grid;
    margin: 16px;
    width: 100%;
    cursor: pointer;
    grid-template-columns: 42px 1fr;
    column-gap: 8px;
  
  // toggle-bakgrunnen
  &:before {
    width: 36px;
    height: 16px;
    cursor: pointer;
    top: calc(50% - 8px);
    left: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    content: '';
    display: block;
    grid-column: 1;
    grid-row: 1;
    position: relative;
    border: none;
  }
  
  // toggle-sirkelen
  &:after {
    content: '';
    height: 20px;
    width: 20px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    display: block;
    grid-column: 1;
    grid-row: 1;
    position: relative;
  }
}

input:checked + .switch-label:after {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}
