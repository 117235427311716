.cluster-navigation {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "previous counter next";
  padding: 4px 8px;
  align-items: center;

  .previous-tooltip-button {
    grid-area: previous;
    justify-self: start;
    svg {
      transform: rotate(180deg);
    }
  }
  
  .next-tooltip-button {
    grid-area: next;
    justify-self: end;
   
  }


  svg {
    vertical-align: middle;
    font-size: inherit;
    margin: 0 6px;
  }
  
  .counter {
    grid-area: counter;
  }
}

.tooltip-header {
  display: flex;
  padding: 16px 36px ;


  .tooltip-content {
    flex-grow: 2;
    text-align: left;

    .tooltip-title {
      margin-left: 3px;
      font-size: 18px;
    }
    
  }

  .close-button {
    margin: auto;
    flex-basis: content;
  }
}
.tooltip-body {
  padding: 0 36px;
  overflow-y: auto;
}

.mobile-tooltip {
  width: 100%;
  height: fit-content;
  position: absolute;
  bottom: 0;
  z-index: 9;
  background-color: white;
  
  
}

.large {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}