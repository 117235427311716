@import '../../styles/globals.scss';

.attribution-wrapper {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 10px;
  right: 2.5%;
  max-width: 95%;
  font-size: 14px;
  padding: 6px;
  z-index: 8;
  pointer-events: none;
}

@media (min-width: $bp_small) {
  .attribution-wrapper {
    right: 10px;
  }
}
