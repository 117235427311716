.menu-button-wrapper {
    padding: 2px;
    z-index: 12;
    position: fixed;
    right: 22px;
    top: 31px;
}

.menu-button {
    border: 0;
    padding: 8px 16px;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    font-weight: inherit;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    span {
        font-size: 16px;
        padding: 0px;
        margin: 0px;
        font-weight: inherit;
    }
}

.menu-icon {
    margin-left: 8px;
}

.menu-button--mobile {
    padding: 5px;
    height: 45px;
    width: 45px;
    margin-left: auto;
}
