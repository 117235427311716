@import "../../styles/globals";

.menu-bar {
    z-index: 50;
    padding: 16px;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;

    .logo-title-container {
        user-select: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
    }

    .logos {
        height: 45px;
        margin-right: 12px;
        
        a:first-of-type {
            margin-right: 10px;
        }
    }

    .site-name {
        margin: 0;
    }

    .menu-watermark {
        position: relative;
        top: 15px;
        left: -60px;
        transform: rotate(-15deg);
    }
}

.search--mobile {
    -webkit-box-flex: 1;
    -ms-flex: 1 2;
    flex: 1 2;
}

@media (min-width: $bp_medium) {
    .menu-bar {
        padding: 24px;
        
        .logos {
            height: 60px;
            margin-right: 24px;

        }
    }
}
