@import '../../styles/globals.scss';
.yearsInfoWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 48px -6%;
}

.first-area-chart,
.second-area-chart {
    background-color: #f7f7f7;
    padding: 0 10px 10px;
    margin-top: 12px;
    width: 100%;
    
    .header {
        font-weight: bold;
        padding: 18px;
    }
}
@media (min-width: $bp_xtra_small) {
    .yearsInfoWrapper {
        margin: 48px 0;
    }
}

@media (min-width: $bp_medium) {
    .first-area-chart,
    .second-area-chart {
        width: 595px;
        padding: 0 20px 20px;
    }
}

@media (min-width: 1440px) {
    .yearsInfoWrapper {
        justify-content: flex-start;
    }

    .first-area-chart {
        margin-right: 24px;
    }
}
