@use '../../styles/theme' as *;
@import '../../styles/globals.scss';

.accessibility-modal{
  position: absolute;
  width: 450px;
  top: 5%;
  left: calc(50% - 150px);
  padding: 20px 30px;
  background-color: var(--background-color);
  border: 2px solid var(--darkPrimaryColor);
  box-shadow: 8px 8px 16px var(--mdGreyColor60);
  
  .modal-title {
    display: flex;
    h2{
        flex: 1 2;
    }
  }
}

.accessibility-modal-content {
  .browsers {
    &::first-line {
      font-weight: bold;
    }
  }
  
  ul {
    padding: 0;
    
  }

  li {
    list-style-type: none;
    padding: 2px 0;
  }

  kbd {
    border-radius: 2px;
    padding: 2px;
    border: 1px solid var(--greyColor80);
  }
}

@media (min-width: $bp_small) {
  .accessibility-modal{
    width: 480px;
    top: calc( 50% - 400px);
    left: calc(50% - 200px);
  }
}
