.locationSearch {
    display: flex;
    z-index: 2;
    width: 100%;

    .icon-button-wrapper {
        height: 38px;
        width: 38px;
        padding: 7px;
        margin: auto 0;
    }

    .searchable-select__placeholder {
        // Får ikke til å nå riktig placeholder-klasse fra noe annet sted, så må hardkode fargeverdien inn her
        color: #757575;
    }
    
}
.locationSearch-label {
    width: 100%;
}

.MuiInput-underline::before {
    border-bottom: none !important;
}
.MuiInput-underline::after {
    border-bottom: none !important;
}