@import '../../styles/globals.scss';

.MuiTableCell-sizeSmall {
  padding: 6px 8px 6px 8px;
}

td {
  padding: 8px 16px;
}

.statistics-table-wrapper {
  margin: 100px -6%
}

.small-table-reindeer-left-column {
  max-width: 70px
}

.small-table-sheep-left-column {
  max-width: 40px
}

@media (min-width: $bp_xtra_small) {
  .statistics-table-wrapper {
    margin: 100px auto;
  }

}

@media (min-width: 600px) {
  .small-table-reindeer-left-column {
    max-width: 100px;
  }

  .small-table-sheep-left-column {
    max-width: 100px;
  }
}
