$footer_height_full: 120px;
$footer_height_mobile: 250px;

//Breakpoints
$bp_xtra_small: 420px;
$bp_small: 600px;
$bp_medium: 960px;
$bp_large: 1280px;

//Sidepanel
$sidepanel_transition: 175ms all;

$h3size: 16px;
