@import "../../styles/colors.scss";

.message {
  position: absolute;
  z-index: 1;
  padding: 25px 25px 25px 25px;
  font-size: 18px;
  top: unset;
  bottom: 85px;
  background-color: $color--system-message-green;

  button:hover {
    background-color: $color--system-message-hover-green;
  }

  &.loadingerrormessage {
    top: 85px;
    bottom: unset;
    background-color: $color--system-message-red;

    button:hover {
      background-color: $color--system-message-hover-red;
    }
  }

  &.errormessage {
    background-color: $color--system-message-red;

    button:hover {
      background-color: $color--system-message-hover-red;
    }
  }

  &.warningmessage {
    background-color: $color--system-message-yellow;

    button:hover {
      background-color: $color--system-message-hover-yellow;
    }
  }

  header {
     font-size: 20px;
   }

   .info-icon {
     margin-right: 20px;
     height: 40px;
     width: 40px;
   }

   .close-icon {
     padding: 0px;
     border-radius: 0;
     width: unset;
     height: unset;
   }

   .message-content {
     display: flex;
     align-items: center;

     .message-text {
       flex-grow: 1;
     }
   }
 }

 @media (min-width: 600px) {
   .message {
     width: fit-content;
     display: flex;
     flex-direction: column;
     min-width: 600px;

     &.open {
       left: 450px;
       transition: 175ms all;
     }

     &.closed {
       left: 30px;
       transition: 175ms all;
     }
   }
 }