@use '../../styles/theme' as *;

// MD Date input styling. Hentet fra 
// https://github.com/miljodir/md-components/blob/main/packages/css/src/formElements/input/input.css
// Det er gjort noen tilpasninger mot rovbase-stilinga

// NB! Husk å oppdatere denne fila med endringer når md-components kommer med nye versjoner
// Feks er det ventet at bruken av px skal erstattes med em så følg med på det

.md-input__outer-wrapper--small {
  max-width: 336px;
}

.md-input {
  padding: 1em 2em 1em 1em;
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid var(--greyColor60);
  margin: 1px;
  color: var(--greyColor);
  font-size: 16px;
  box-sizing: border-box;
}

.md-input--small {
  padding: 0.5em 2em 0.5em 1em;
}

.md-input:not(.md-input.md-input--readonly):not(.md-input.md-input--disabled):active,
.md-input:not(.md-input.md-input--readonly):not(.md-input.md-input--disabled):focus {
  outline: 2px solid var(--primaryColor80);
  outline-offset: -2px;
  border-color: transparent;
}

.md-input.md-input--disabled:focus,
.md-input.md-input--readonly:focus {
  outline: 0;
  cursor: default;
}

.md-input.md-input--disabled {
  background-color: var(--greyColor20);
}

.md-input.md-input--readonly:not(.md-input.md-input--disabled) {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid var(--greyColor60);
}

.md-input.md-input--error {
  border-color: var(--errorColor);
}

.md-input.md-input--has-suffix {
  padding-right: 5em;
}

.md-input--small.md-input--has-suffix {
  padding-right: 3.5em;
}

.md-input.md-input--has-prefix {
  padding-left: 2.5em;
}

.md-input--small.md-input--has-prefix {
  padding-left: 1.8em;
}

.md-input__wrapper {
  position: relative;
  margin: 0.7em 0 0.3em 0;
}

.md-input__wrapper.md-input__wrapper--small {
  max-width: 336px;
}

.md-input__label {
  display: flex;
  align-items: flex-end;
  font-weight: 600;
}

.md-input__label > * + * {
  margin-left: 1em;
}

.md-input__help-button {
  display: flex;
  flex-grow: 1;
}

.md-input__error {
  color: var(--errorColor);
  font-size: 0.88em;
}

.md-input__help-text {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
}

.md-input__help-text--open {
  padding-top: 0.5em;
  max-height: 2000px;
  transition: max-height 0.5s ease-in;
}

.md-input__prefix {
  position: absolute;
  top: 1.1em;
  left: 1em;
  height: 16px;
  width: 16px;
  display: flex;
  color: var(--primaryColor);
}

.md-input__prefix.md-input__prefix--disabled {
  color: var(--greyColor60);
}

.md-input__wrapper.md-input__wrapper--small .md-input__prefix {
  top: 0.65em;
  left: 0.5em;
}

.md-input__suffix {
  position: absolute;
  top: 1.05em;
  right: 0.9em;
  display: flex;
}

.md-input__suffix > * + * {
  margin-left: 0.5em;
}

.md-input__error-icon {
  width: 20px;
  height: 20px;
  margin-left: 0.5em;
  color: var(--errorColor);
}

.md-input__wrapper.md-input__wrapper--small .md-input__suffix {
  top: 0.55em;
  right: 0.7em;
}

.md-input__wrapper.md-input__wrapper--small .md-input__suffix > * + * {
  margin-left: 0.3em;
}

/* Placeholder "hacks" */
.md-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--greyColor40);
  font-family: 'Open sans';
}
.md-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--greyColor40);
  font-family: 'Open sans';
}
.md-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--greyColor40);
  font-family: 'Open sans';
}
.md-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--greyColor40);
  font-family: 'Open sans';
}

/* Hacks for hiding arrows if type=number */
.md-input.md-input--hide-number-arrows[type='number'],
.md-input.md-input--hide-number-arrows::-webkit-outer-spin-button,
.md-input.md-input--hide-number-arrows::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 1px;
}