@use '../../styles/theme' as *;
@use '../../styles/spacing';

.searchfilter {
  margin: spacing.$spacing--l spacing.$spacing--m;
    
  h2, h3 {
    font-size: 16px;
    font-weight: bold;
  }
  
  .title {
    font-size: 16px;
    font-weight: normal;
  }
  
  .help-message-autosearch {
    padding: 16px;
  }
  
  .checkbox-toggle-all-mobile {
    margin: 20px 0 -16px;
  }
  
  // For kun top-level div innenfor searchfilter
  & > div {
    margin: spacing.$spacing--m 0;
  }

  .searchbar-mobile {
    position: relative;
  }
}

.searchfilter-expansion-panel {
  margin: 0 (- spacing.$spacing--m);
  border-top: 1px solid var(--primaryColor10);

  &:last-child {
    border-top: 1px solid var(--primaryColor10);
    border-bottom: 1px solid var(--primaryColor10);
  }

  &.expansion-panel {
    margin-bottom: 0;
  }
}

.registration-filter {
  .yngling-button-group {
    width: 60%;
    min-width: 200px;
    display: grid;
    grid-template-columns: auto auto;
  }

  .svensk {
    width: 100%;
  }

  .observations-list {
    margin-top: spacing.$spacing--m;
  }

  .info-message {
    width: 100%;
    min-height: 44px;
    padding: spacing.$spacing--s;
    margin-bottom: spacing.$spacing--xs;
    display: flex;
    align-self: flex-end;
    background-color: var(--secondaryColor20);
    border: 1px dashed var(--primaryColor);

    svg {
      margin: auto 8px;
    }

    span {
      margin-left: 8px;
    }

    a {
      color: var(--primaryColor);

      &:focus {
        outline: 1px solid var(--primaryColor80);
        outline-offset: 1px;
      }

      &:hover {
        color: var(--primaryColor120);
      }
    }
  }
}