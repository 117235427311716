@import '../../styles/globals.scss';

.sidepanel_wrapper {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 420px;
    box-sizing: border-box;
    display: block;
    transition: $sidepanel_transition;
    //overflow-y: auto;

    & > * {
        transition: $sidepanel_transition;
    }
}

.sidepanel_open {
    width: 100%;
    z-index: 10;
}

.sidepanel_closed {
    left: -420px; //  width: 0px;
    & > * {
        overflow: hidden;
    }

    .SearchresultList_wrapper_open {
        overflow: hidden;
    }
}
.sidepanel_searchresultPaper {
    position: absolute;
    //  transform: translateY(0) !important;
    top: 80px;
    width: 100%;
    bottom: 0;
    visibility: hidden;
}
.sidepanel_searchresultPaper_open {
    background-color: white;
    visibility: visible;
}
.sidepanel_btngroup {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 12px;
    column-gap: 12px;
    padding: 24px 12px;
    margin-bottom: 24px;
}

.sidepanel-filter-content {
    overflow: auto;
    height: 100%;
    padding-bottom: 64px;
}

.btnToggleSidepanel {
    transition: $sidepanel_transition;
    z-index: 8;
    border: none;
    height: 60px;
    width: 50px;
    cursor: pointer;
}

.btnOpenSidepanel {
    position: absolute;
    left: 0;
    top: 50px;
    border-radius: 0px 2px 2px 0px;
}

.btnCloseSidepanel {
    float: right;
    margin-top: 20px;
    z-index: 5;
    height: 60px;
    border-radius: 2px 0px 0px 2px;
}

@media (min-width: $bp_small) {
    .sidepanel_open {
        width: 420px;
    }
    .sidepanel_searchresultPaper {
        top: 90px;
    }

    .sidepanel_btngroup {
      padding: 40px 12px;
      margin-bottom: 40px;
    }

    .btnCloseSidepanel {
        position: absolute;
        left: 420px;
        top: 30%;
        z-index: 10;
    }
    .btnCloseSidepanel {
        border-radius: 0px 2px 2px 0px;
    }

    .btnOpenSidepanel {
        position: absolute;
        left: 0;
        top: 30%;
    }

    .btnToggleSidepanel {
        height: 70px;
    }
}
