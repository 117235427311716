.share {
    margin: 8px 0; 
}

.share-title {
    font-weight: 700;
}

.share-buttons-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px; // Negativ margin på venstre side for å kompensere for 8px i padding på knappen.
}

.copy-link-wrapper {
    text-decoration: none;
    background-color: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-right: 8px;

    svg {
        margin-right: 8px;
    }
}

.mail-link-wrapper {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 8px;
    
    svg {
        margin-right: 8px;
    }
}

.feedback-message {
    width: 100%;
    min-height: 44px;
    margin: 6px 0px;
    display: flex;
    align-items: center;
    align-self: flex-end;

    svg {
        margin: auto 8px;
    }

    span {
        margin: auto 0;
    }
}
