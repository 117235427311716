.result-button-item {
    width: 100%;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    border-bottom: 1px solid;
    // padding: 8px;
}

.searchresult-icon {
    // width: 50px;
    float: left;
    margin-right: 8px;
}

.datatype-description {
    text-align: left;
    font-weight: bold;
}

.result-text-wrapper {
    line-height: 18px;
    font-size: 14px;
    padding: 8px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
