@import '../../styles/globals.scss';
.statisticalInfoBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -6%;
    //  flex-direction: column;
    ///overflow: auto;
}
.firstInfoBox,
.secondInfobox {
    padding: 0 10px 10px;
    background-color: #f7f7f7;
    margin-top: 12px;
    width: 100%;
    //height
    //  border: 0.2px solid #c3c3c3;
    .header {
        font-weight: bold;
        padding: 16px 10px;
    }
}

.uu-test {
    p {
        visibility: hidden;
    }
}

.legend-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    .legend-list-item {
        margin-right: 12px;
        display: flex;

        span {
            line-height: 28px;
        }
    }
}

@media (min-width: $bp_xtra_small) {
    .statisticalInfoBox {
        margin: unset;
    }
}

@media (min-width: $bp_medium) {
    .statisticalInfoBox {
        //    flex-direction: row;
    }

    .firstInfoBox,
    .secondInfobox {
        width: 595px;
        padding: 0 20px 20px;

      .header {
        padding: 16px;
      }
    }
}

@media (min-width: 1440px) {
    .statisticalInfoBox {
        justify-content: flex-start;
    }

    .firstInfoBox {
        margin-right: 24px;
    }
}
