@import '../styles/text.scss';
@import '../styles/globals.scss';
html,
#rovbaseApp,
.contentWrapper,
.content {
    height: 100%;
}
body {
    height: 100vh;
    font-family: $main-font-family;
    color: #3e3e3e;
    padding: 0 !important;
    // font-weight: 300;
    * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    overflow-x: hidden;
}

button {
    font-size: inherit;
    font-family: $main-font-family;
    color: #3e3e3e;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
}

body,
.main-page {
    margin: 0;
}

h1 {
    font-weight: normal;
    font-size: 24px;
    margin: 12px 0;
}

h2 {
    font-weight: normal;
    font-size: 22px;
    margin: 12px 0;
}

h3 {
    font-weight: normal;
    font-size: 18px;
    margin: 6px 0px;
}

a {
    color: inherit;
}

ul {
    padding: 0;
    margin: 0;
}

.accessibility-button {
    position: absolute;
    left: -2000px;
    top: 0;
}

.centerContent {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 60px;
}

.content {
    display: block;
    height: 100%;
}

.content-wrapper {
    main {
        width: 90%;
        margin: 0 auto;
        line-height: 1.5rem;
        padding: 20px 0;
    }
    
    &.compact-mobile main{
        width: 100%;
        //margin-bottom: 60px;
    }

    .open-in-new {
        margin-left: 4px;
        vertical-align: middle;
        font-size: inherit;
    }
}

.accessibility {
    width: 100%;
    max-width: 750px;

    section {
        margin-bottom: 40px;
    }
    h4 {
        margin-block-end: 0.5em;
    }
    
    ul {
        margin-block-start: 0.5em;
        padding-inline-start: 20px;
    }
}

.accessibility-content {
    margin-bottom: 64px;
}

.searchBar_wrapper {
    position: absolute;
    flex: 1 2;
    left: 7.5%;
    top: 12px;
    width: 85%;
    z-index: 50;
    height: 44px;
    transition: $sidepanel_transition;
}

.searchBar_wrapper_offset {
    position: relative;
    top: 30px;
    left: 100vw;
    width: calc(100vw - 550px);
    max-width: 320px;
    z-index: 11;
    transition: $sidepanel_transition;
}

.search-n-menu {
    position: absolute;
    display: flex;
    width: 100%;
    padding: 2vw;
    z-index: 50;
    transition: $sidepanel_transition;
}

.search-n-menu--offset {
    position: absolute;
    left: 0vw;
    transition: $sidepanel_transition;
}

.contactinfo_contactPage {
    line-height: 28px;
    a {
        text-decoration: underline;
    }

    .contactinfo__text {
        color: #222222;
        margin: 24px 0px 24px 0px;

        .contactinfo__name {
            font-size: 18px;
            font-weight: bold;
        }
    }
}

.compensation-loader {
  position: fixed;
  left: 50%;
  top: 50%;
}

@media (min-width: $bp_small) {
    .centerContent {
        width: 80%;
    }

    .searchBar_wrapper {
        width: 320px;
        left: 30px;
        top: 23px;
    }

    .searchBar_wrapper_offset {
        left: 430px;
    }

    .search-n-menu {
        padding: 12px;
    }

    .content-wrapper {
        main {
            width: 80%;
        }
    }
}

@media (min-width: $bp_medium) {

    h1 {
      font-size: 36px;
    }
  
    h2 {
      font-size: 28px;
    }
  
    h3 {
      font-size: 20px;
    }
    .searchBar_wrapper_offset {
        width: 320px;
        left: 450px;
    }

    .content-wrapper {
        .about, .contact-page {
            width: 650px;
        }
    }
}

