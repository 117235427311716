.date-filter {
    display: flex;
    flex-direction: column;

    .presets-button-group {
        display: grid;
        grid-template-columns: auto auto auto auto;
        min-height: 50px;
        margin: 0 -3px;
    }

    .date-preset-button {
        border-radius: 0;
        font-size: 16px;
        line-height: 120%;
        width: 100%;
        padding: 8px;
        font-family: inherit;

        span {
            margin: 0 4px;
        }
    }

    .date-picker-wrapper {
        width: 200px;
        margin-top: 1em;
    }
}







