.tooltip {
    z-index: 10;
    width: 380px;
  
    // For å passe på at tooltipen ikke går utenfor skjermen (spesielt til mobil)
    max-height: 95vh;
    bottom: 10px;
    right: 10px;
    white-space: pre-line;
    position: absolute;
    padding: 16px 20px ;
    
    .title-banner {
        min-height: 44px;
        margin: -16px -20px 0 -20px;
        font-size: 18px;
        display: grid;
        grid-template-columns: auto 44px;

        .titles {
            padding: 6px 20px 6px 20px;
            text-align: left;
            margin-right: -44px;
        }
        
        .title {
            padding: 5px 28px 5px 0;
        }
        
        .individ {
            font-size: 16px;
            line-height: 140%;
            font-weight: bold;
            padding: 6px 28px 6px 0 ;
            
        }
        
        .cluster {
            font-size: 16px;
            text-align: center;
            line-height: 140%;
            font-weight: bold;
            width: 100%;
            padding: 5px 0;
            margin-right: -44px;
        }
        
        .close-button {
            margin: auto;
        }
    }

    .extra-title {
        font-size: 20px;
        padding-top: 16px;
    }
}

.tooltip-header-info {
    display: flex;
    margin: 8px 0 8px -6px;

    .tooltip-icon {
        flex: 0 0 70px;

    }

    .info-grid {
        padding-top: 8px;
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: 22px auto;
        text-align: left;

        .field-label {
            font-weight: bold;
            margin-right: 12px;
        }
    }
}

.tooltip-description {
    margin: 24px 0;
}

.stedfesting-info {
    margin: 24px 0;
}

.stedfesting-info-pair {
    display: grid;
    grid-template-columns: 120px 1fr;
    padding: 6px 0;

    span {
        font-weight: bold;
    }
}

.navigate-tooltip {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    align-items: center;
    
    > * {
      flex: 1;
      text-align: center;
    }
  
    .counter {
        line-height: 40px;
    }

    button {
        min-width: 106px;
    }
}

.individ-link {
    padding-top: 12px;
}
.barcode-link {
    padding-top: 12px;
}

.observation-types {
    padding-top: 12px;
}