$color--petrol: #00615C;
$color--white: #ffffff;
$color--background-white: #fefefe;
$color--input-border-grey: #8a8a8a;
$color--black-text: #222222;

$color--system-message-red: #F3D5CF;
$color--system-message-green: #D7E6E6;
$color--system-message-yellow: #fff7cf;

$color--system-message-hover-red: #f0b2a6;
$color--system-message-hover-green: #c4d2d2;
$color--system-message-hover-yellow: #e2dab1;

// NORSKE FARGER
$mdPrimaryColor: #005e5d;
$mdPrimaryColor120: #005251;
$mdPrimaryColor80: #337e7d;
$mdPrimaryColor20: #ccdfde;
$mdPrimaryColor10: #e5eeee;

$mdLightPrimaryColor: #40c1ac;
$mdDarkPrimaryColor: #005251;

$mdSecondaryColor: #ece7d2;
$mdSecondaryColor80: #efebdb;
$mdSecondaryColor60: #f3f0e4;
$mdSecondaryColor40: #f7f5ed;
$mdSecondaryColor20: #fbfaf6;

$mdTextColor: #222222;

$mdWarningBackgroundColor: #fce4ca;
$mdWarningColor: #de8215;

$mdErrorColor: #ca0000;
$mdErrorColor120: #b7090d;

$mdGreyColor: #222222;
$mdGreyColor80: #4e4e4e;
$mdGreyColor60: #808080;
$mdGreyColor40: #a6a6a6;
$mdGreyColor20: #d2d2d2;
$mdGreyColor10: #e8e8e8;

$mdGreenColor60: #b3e8c2;


// SVENSKE FARGER
$nvPrimaryColor: #3A588F;
$nvPrimaryColor120: #244582;
$nvPrimaryColor80: #5c729c;
$nvPrimaryColor20: #D8DEE9;
$nvPrimaryColor10: #EBEEF4;

$nvLightPrimaryColor: #5393C3;
$nvDarkPrimaryColor: #244582;

$nvSecondaryColor: #D8D8D8;
$nvSecondaryColor80: #E0E0E0;
$nvSecondaryColor60: #E8E8E8;
$nvSecondaryColor40: #EFEFEF;
$nvSecondaryColor20: #F7F7F7;
