$spacing--xxs: 4px;
$spacing--xs: 8px;
$spacing--s: 16px;
$spacing--m: 24px;
$spacing--l: 32px;
$spacing--xl: 48px;
$spacing--xxl: 64px;

@mixin margin($space) {
  margin: $space;
}

@mixin margin-x($space) {
  margin-left: $space;
  margin-right: $space;
}

@mixin margin-y($space) {
  margin-top: $space;
  margin-bottom: $space;
}

@mixin padding($space) {
  padding: $space;
}

@mixin padding-x($space) {
  padding-left: $space;
  padding-right: $space;
}

@mixin padding-y($space) {
  padding-top: $space;
  padding-bottom: $space;
}