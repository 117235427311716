@import "../../styles/colors";
@import "../../styles/font";
@import "../../styles/spacing";

// Delvis generert fra
// http://www.menucool.com/tooltip/css-tooltip

$background: $color--background-white;
$border: $color--input-border-grey;

.tooltip-container {
  display: inline-block;
  position: relative;
  align-self: center;
  text-align: left;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $color--black-text;
  font-family: $secondary-font-family;
  font-size: 14px;
  margin: 0 $spacing--xxs;

  .boble {
    min-width: 200px;
    top: -20px;
    left: 60%;
    transform: translate(-46%, -110%);
    padding: 10px 20px;
    background-color: $background;
    font-weight: normal;
    border-radius: 5px;
    position: absolute;
    z-index: 10;  
    border: 1px solid $border;

    &.boble-hidden {
      display: none;
    }


    i {
      position:absolute;
      top:100%;
      left:50%;
      margin-left:-12px;
      width:24px;
      height:12px;
      overflow:hidden;
    }

    i::after {
      content:'';
      position:absolute;
      width:12px;
      height:12px;
      left:50%;
      transform:translate(-50%,-50%) rotate(45deg);
      background-color: $background;
      border:1px solid $border;
    }

    &.left {
      transform: translate(-100%, 0%);
      left: -15px;

      i {
        transform: rotate(-90deg);
        left: 102.8%;
        top: 23px;
      }
    }

    &.right {
      transform: translate(20%, 0%);
      left: -12px;

      i {
        transform: rotate(90deg);
        left: -2.14%;
        top: 23px;
      }
    }

    &.down { //TODO
    }

  }
}

.tooltip-btn {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:focus {
    border-color: $color--white;
    outline: 2px solid $color--petrol;
    outline-offset: 0;
  }

  .circle {
    fill: $color--petrol;
  }
  .text {
    fill: $color--white;
  }
}