.check-all-button {
    margin-left: -3px;
    margin-right: 15px;
}

.checkbox-content {
    display: grid;
    grid-template-columns: 1fr;
    
    .checkbox-text {
        margin-top: 15px;
    }
}

.double {
    grid-template-columns: 1fr 1fr;
}



