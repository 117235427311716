@import '../../styles/globals.scss';
.content-wrapper {
    position: relative;
    min-height: 100vh;
    padding-bottom: $footer_height_mobile;
}

@media (min-width: $bp_medium) {
    .content-wrapper {
        padding-bottom: $footer_height_full;
    }
}
