@import '../../styles/globals.scss';


.compact-mobile .report-page{
  p, h2{
    width:90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.report-container {
  max-width:2000px;
  button.fullscreen-button {
    align-items: center;
    display: inline-flex;
    float:right;
    margin-bottom: 16px;
    padding: 8px 16px 8px 12px;
    .fullscreen-icon {
      margin-right: 4px;
    }
  }
  
  a.goto-top{
    display: inline-block;
    width: 100%;
    height: 2rem;
    line-height:2rem;
    text-align: center;
    background-color: white;
  }
  
  .report-component {
    width:100%;
    aspect-ratio: 16/9;
    iframe{
      width: 100%;
      height: 100%;
      border:none;
    }
    &.mobileLayout {
      aspect-ratio: unset;
      height: calc(100vh - 2rem);
      overflow:hidden;
    }
  }
}