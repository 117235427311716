@use '../../styles/theme' as *;
@use '../../styles/globals';

.carnivore-filter {
  .main-carnivore-box {
    display: grid;
    grid-template-columns: 1fr;
    border: 0.5px solid var(--primaryColor20);
  }
  
  .switch-frame {
    color: var(--primaryColor);
    border: 0.5px solid var(--primaryColor20);

    &:focus-within {
      background-color: var(--primaryColor10);
    }
  }

  .carnivore-switch-box {
    border: 0.5px solid var(--primaryColor20);
  }

  @media (min-width: globals.$bp_xtra_small){
    .main-carnivore-box {
      grid-template-columns: 1fr 1fr;
    }
  }
}

