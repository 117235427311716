@font-face {
    font-family: Palatino;
    src: url(/assets/fonts/PalatinoWoff/38D075_0_0.woff) format('woff');
    font-weight: 300;
}

@font-face {
    font-family: Palatino;
    src: url(/assets/fonts/PalatinoWoff/38D075_1_0.woff) format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: Palatino;
    src: url(/assets/fonts/PalatinoWoff/38D075_2_0.woff) format('woff');
    font-weight: 400;
}
@font-face {
    font-family: Palatino;
    src: url(/assets/fonts/PalatinoWoff/38D075_3_0.woff) format('woff');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: Palatino;
    src: url(/assets/fonts/PalatinoWoff/38D075_4_0.woff) format('woff');
    font-weight: 600;
}
@font-face {
    font-family: Palatino;
    src: url(/assets/fonts/PalatinoWoff/38D075_5_0.woff) format('woff');
    font-weight: 700;
}

$main-font-family: 'Palatino';
