@use '../../styles/theme' as *;
@use '../../styles/spacing';
@import '../../styles/globals.scss';

.footer {
    position: absolute;
    bottom: 0;
    height: $footer_height_mobile;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 5%;
    background-color: var(--backgroundColor);
    border-top: 1px solid var(--greyColor20);
}

.footer-link {
  border-bottom: 1px solid var(--greyColor20);
  padding: spacing.$spacing--s 0;
  width: 100%;

  &:active, &:visited, &:link {
    color: var(--textColorDark);
  }

  &:last-of-type {
    border-right: 0;
    border-bottom: 0;
  }

  & a {
    color: var(--primaryColor);
  }
}

.footer-logo-wrapper {
  margin: spacing.$spacing--m 0;
}

.footer-content-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: spacing.$spacing--m;
}

@media (min-width: $bp_small) {
  .footer {
    padding: 0 10%;
  }
}

@media (min-width: $bp_medium) {
  .footer {
    flex-flow: row;
    height: $footer_height_full;
    align-items: center;
  }
  
  .footer-link {
    border-right: 1px solid var(--greyColor20);
    border-bottom: 0;
    padding: 0 spacing.$spacing--s;
    margin: spacing.$spacing--xs 0;
    width: fit-content;
    height: fit-content;
  }

  .footer-content-wrapper {
    padding-left: spacing.$spacing--xl;
    padding-bottom: 0;
  }
}
