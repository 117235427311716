.searchbar {
    display: flex;
   
    .input-wrapper {
        width: 100%
    }
}

.searchbar-label {
    width: 100%;
}

.searchbar__suggestionBox {
    position: absolute;
    top: 42px;
    max-height: 200px;
    width: 100%;
    z-index: 100;
    background-color: white;
    overflow-y: scroll;
    -webkit-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 9px 10px -5px rgba(0, 0, 0, 0.75);
}

.searchbar__suggestionItem {
    width: 100%;
    color: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    background-color: white;
    text-align: left;
    border-bottom: 0.2px solid lightgrey;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;

    &:nth-last-child(1) {
        border-bottom: none;
    }
    &:hover {
        background-color: #d0e3e2;
    }
}

// .searchbar__suggestionItem_text {
//     display: flex;
//     flex-direction: column;
// }

@media (min-width: 600px) {
    .searchbar__suggestionBox {
        left: 0;
        z-index: 70;
        max-height: 400px;
        top: 45px;
        -webkit-box-shadow: 0px 12px 28px -6px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 12px 28px -6px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 12px 28px -6px rgba(0, 0, 0, 0.75);
    }
}
