@use '../../styles/theme' as *;

.checkbox-wrapper {
  margin: 0 10px 10px 0;
}

.checkbox-input {
  opacity: 0;
  position: absolute;
  margin-right: 100px;
  background-color: var(--backgroundColor);
  
  &:checked ~label svg {
    fill: var(--primaryColor);
  }
  
  &:focus + .checkbox-label {
    outline: 1px solid var(--primaryColor80);
    outline-offset: 2px;
  }
}
.checkbox-label {
  display: inline;
  border: 1px solid var(--greyColor60);
}

.checkbox-input + .checkbox-label {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
}

.checkbox-input:checked + .checkbox-label {
  .checkmark {
    height: 12px;
    margin: 3px 1px;
    display: inline;
    position: absolute;
  }
}

.checkbox-text {
  display: grid;
  grid-template-columns: 28px auto;
  min-height: 25px;
  line-height: 23px;
  margin-top: 4px;
  cursor: pointer;
}

.checkmark {
  display: none;
}