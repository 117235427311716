@use '../../styles/theme' as *;
@import '../../styles/spacing';

.about-statistics {
  max-width: 500px;
  background-color: var(--secondaryColor60);
  padding: $spacing--m;
  margin-bottom: $spacing--m;
}

.info-list {
  padding-left: $spacing--m;
}

.statistics-list {
  li {
    display: flex;
    
    span:first-of-type {
      flex-basis: 60px;
      text-align: right;
      margin-right: $spacing--xs;
    }
  }
}

.about-ingress {
  font-size: 18px;
  width: 90%;
}