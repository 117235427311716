.select-year {
    min-width: 130px;
    margin-right: 20px;

    div {
    }
    > div {
        min-height: 44px;
        border-radius: 0;
        border: 1px solid #c3c3c3;
    }
}

.select-year-menu {
    min-height: 44px;
    position: absolute;
    width: 100%;
    z-index: 30;
}

.select-year-label {
    margin: 6px 0px;
    font-weight: bold;
    display: inline-block;
}
